import axios from 'axios';

class ApiClient {
	constructor() {
		this.client = axios.create( {
			baseURL: '/api/',
			timeout: 10000,
			withCredentials: true,
			paramsSerializer: function( params ) {
				return JSON.stringify( params, { arrayFormat: 'comma' } );
			}
		} );
	}

	async getSession() {
		return this.client.get( 'event-booking/get-session' )
			.then( response => {
				if ( response.status === 200 ) {
					return response.data;
				}
			} );
	}

	async getRemainingTicketTypes() {
		return this.client.get( 'event-booking/remaining-tickets' )
			.then( response => {
				if ( response.status === 200 ) {
					return response.data;
				}
			} );
	}

	async addTicketBooking( payload ) {
		const response = await this.client.post( 'event-booking/add-ticket', payload, {
			headers: {
				'X-CSRF-Token': window.csrfToken
			}
		} );
		if ( response.status === 200 ) {
			return response.data;
		}
	}

	async removeTicketBooking( payload ) {
		const response = await this.client.post( 'event-booking/remove-ticket', payload, {
			headers: {
				'X-CSRF-Token': window.csrfToken
			}
		} );
		if ( response.status === 200 ) {
			return response.data;
		}
	}
}

export default new ApiClient();
