
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { configure, stats, hits, pagination } from 'instantsearch.js/es/widgets';
import { customSearchBox, customRefinementList } from './algolia-components';
import moment from 'moment';

( () => {

	// Algolia main search

	const algolia_search = document.querySelector( '.js-algolia-search' );
	if ( !algolia_search ) {
		return;
	}

	const client = algoliasearch(
		algolia_search.dataset.algoliaAppId,
		algolia_search.dataset.algoliaSearchApiKey
	);

	const search = instantsearch( {
		indexName: algolia_search.dataset.algoliaIndexName,
		searchClient: client,
		searchFunction( helper ) {
			if ( helper.state.query ) {
				helper.search();
			}
		}
	} );

	const now_timestamp = ( Date.now() / 1000 );
	const not_set = 1;

	search.addWidgets( [
		configure( {
			hitsPerPage: 10,
			facetsRefinements: {
				appears_in_search: [true]
			},
			filters: `post_date_timestamp <= ${now_timestamp} AND ( expiry_date < ${not_set} OR expiry_date_timestamp >= ${now_timestamp} )`
		} ),
		customSearchBox( {
			container: document.querySelector( '#algolia-search-query-input' ),
			placeholder: 'Type to search',
			showReset: false,
			showSubmit: false,
			autofocus: false,
			cssClasses: {
				input: 'header-search__input',
				reset: 'invisible',
				submit: 'invisible',
				resetIcon: 'invisible',
				submitIcon: 'invisible',
			},
		} ),
		stats( {
			container: '#algolia-search-results-stats',
			cssClasses: {
				root: 'search-results__stats',
			},
			templates: {
				text: `
					{{#hasNoResults}}{{/hasNoResults}}
					{{#hasOneResult}}1 result for “{{query}}”{{/hasOneResult}}
					{{#hasManyResults}}All results ({{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}) for “{{query}}”{{/hasManyResults}}
				`,
			},
		} ),
		hits( {
			container: '#algolia-search-results-hits',
			templates: {
				item: ( data ) => {
					// Image
					let has_image = true;
					if ( !Object.hasOwnProperty.call( data, 'listing_image' ) ) {
						has_image = false;
					}
					let image_template = '';
					if ( has_image === true ) {
						image_template += `
							<div class="search-result__image-wrapper">
								<img src="${data.listing_image}" alt="" class="search-result__image">
							</div>
						`;
					}

					// Listing text / summary
					let listing_list_template = '';
					if ( data.section_handle === 'News'|| data.section_handle === 'Blog' ) {

						const formatted_date = moment( data.post_date ).format( 'Do MMMM YYYY' );

						listing_list_template += `
							<ul class="icon-list">
								<li class="icon icon--calendar icon-list__item">${formatted_date}</li>
							</ul>
						`;

					} else if ( data.section_handle === 'Events' ) {

						// Open
						listing_list_template += `
							<ul class="icon-list">
						`;

						// Location
						if ( Object.hasOwnProperty.call( data, 'location' ) ) {
							listing_list_template += `
								<li class="icon icon--location icon-list__item">${data.location}</li>
							`;
						}

						// Set formatted date if available
						let formatted_date = null;

						// Have start and ene date
						if ( Object.hasOwnProperty.call( data, 'event_start_date' ) && Object.hasOwnProperty.call( data, 'event_end_date' ) ) {

							if ( moment( data.event_start_date ).format( 'MM' ) === moment( data.event_end_date ).format( 'MM' ) ) {
								// month is the same
								if ( moment( data.event_start_date ).format( 'DD' ) === moment( data.event_end_date ).format( 'DD' ) ) {
									// day is the same
									formatted_date = moment( data.event_start_date ).format( 'Do MMMM YYYY' );
								} else {
									// day is different
									formatted_date = moment( data.event_start_date ).format( 'Do' ) + ' - ' + moment( data.event_end_date ).format( 'Do MMMM YYYY' );
								}
							} else {
								// month is different
								formatted_date = moment( data.event_start_date ).format( 'Do MMMM' ) + ' - ' + moment( data.event_end_date ).format( 'Do MMMM YYYY' );
							}
						// Only have start date
						} else if ( Object.hasOwnProperty.call( data, 'event_start_date' ) ) {
							formatted_date = moment( data.event_start_date ).format( 'Do MMMM YYYY' );
						}

						if ( formatted_date !== null ) {
							listing_list_template += `
								<li class="icon icon--calendar icon-list__item">${formatted_date}</li>
							`;
						}

						// Cost and close
						listing_list_template += `
								<li class="icon icon--pound icon-list__item">${data.cost}</li>
							</ul>
						`;

					} else if ( data.section_handle === 'Churches' ) {

						// Get location
						let location = null;

						if ( Object.hasOwnProperty.call( data, 'county' ) ) {
							location = data.county;
						} else if ( Object.hasOwnProperty.call( data, 'town_city' ) ) {
							location = data.town_city;
						}

						if ( location != null ) {
							listing_list_template += `
								<ul class="icon-list">
									<li class="icon icon--location icon-list__item">${location}</li>
								</ul>
							`;
						}

					} else {

						// Fallback to listing text / introduction text / no results text
						let has_listing_text = true;
						if ( !Object.hasOwnProperty.call( data, 'listing_summary' ) ) {
							has_listing_text = false;
							data.listing_summary = algolia_search.dataset.algoliaFallbackSummary;
						}

						if ( !Object.hasOwnProperty.call( data, 'page_summary' ) ) {
							data.page_summary = algolia_search.dataset.algoliaFallbackSummary;
						}

						let listing_text = data.listing_summary;
						if ( has_listing_text === false ) {
							if ( Object.hasOwnProperty.call( data, 'page_summary' ) ) {
								listing_text = data.page_summary.replace( /(<([^>]+)>)/gi, '' ).slice( 0, 165 ) + '…';
							}
							if ( Object.hasOwnProperty.call( data, 'vacancy_description' ) ) {
								listing_text = data.vacancy_description.replace( /(<([^>]+)>)/gi, '' ).slice( 0, 165 ) + '…';
							}
						}

						listing_list_template += `
							<p>${listing_text}</p>
						`;

					}

					// The result item
					return `
						<div class="search-result">
							<a href="${data.url}" class="search-result__link">
								<div class="search-result__content">
									<div class="search-result__content-inner">
										<div class="tag mb-16">${data.section_handle}</div>
										<h2 class="search-result__heading">${data.title}</h2>
										${listing_list_template}
									</div>
									<div class="search-result__arrow"></div>
								</div>
								${image_template}
							</a>
						</div>
					`;

				}
			},
		} ),
		pagination( {
			container: '#algolia-search-results-pagination',
			scrollTo: '.search-results-inner',
			showFirst: false,
			showLast: false,
			templates: {
				previous: '',
				next: '',
			},
			cssClasses: {
				list: 'pagination',
				previousPageItem: 'pagination__previous',
				nextPageItem: 'pagination__next',
				selectedItem: 'pagination__current',
				disabledItem: 'pagination__disabled-link',
			}
		} ),
		customRefinementList( {
			container: document.querySelector( '#filter-list' ),
			attribute: 'section_handle',
			sortBy: ['name:asc']
		} )
	] );

	search.start();

	const search_input = document.querySelector( '.header-search__input' );
	const search_results_wrapper = document.querySelector( '.search-results' );

	if ( !search_input || !search_results_wrapper ) {
		return;
	}

	search_input.addEventListener( 'input', ( e ) => {
		if ( e.target.value.length < 1 ) {
			search_results_wrapper.classList.add( 'search-results--hidden' );
			document.body.classList.remove( 'show-search-header' );
			return;
		}
		search_results_wrapper.classList.remove( 'search-results--hidden' );
		document.body.classList.add( 'show-search-header' );
	} );

} )();
