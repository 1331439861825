/* eslint-disable snakecasejs/snakecasejs */

import { connectSearchBox, connectRefinementList } from 'instantsearch.js/es/connectors';

export { customSearchBox, customRefinementList };

// Rendering functions
// Desktop search box
const renderSearchBox = ( renderOptions, isFirstRender ) => {
	const { query, refine, clear, isSearchStalled, widgetParams } = renderOptions;

	if ( isFirstRender ) {
		const form = document.createElement( 'form' );
		form.classList.add( 'header-search__form' );
		form.setAttribute( 'role', 'search' );
		form.addEventListener( 'submit', event => {
			event.preventDefault();
			refine( input.value );
		} );

		const inputContainer = document.createElement( 'div' );
		inputContainer.classList = 'header-search__input-container';

		const label = document.createElement( 'label' );
		label.classList = 'header-search__label';
		label.setAttribute( 'for', 'header-search__input' );
		label.setAttribute( 'aria-label', 'Search Churches Conservation Trust' );
		label.textContent = '';

		const input = document.createElement( 'input' );
		input.setAttribute( 'id', 'header-search__input' );
		input.setAttribute( 'type', 'search' );
		input.setAttribute( 'placeholder', widgetParams.placeholder );
		input.setAttribute( 'autocomplete', 'off' );
		input.setAttribute( 'autocorrect', 'off' );
		input.setAttribute( 'autocapitalize', 'off' );
		input.setAttribute( 'spellcheck', 'false' );
		input.classList = widgetParams.cssClasses.input;
		input.addEventListener( 'input', event => {
			refine( event.target.value );
			clear_button.classList.remove( 'hidden' );
		} );

		const loadingIndicator = document.createElement( 'span' );
		loadingIndicator.textContent = 'Loading...';

		const clear_button = document.createElement( 'button' );
		clear_button.textContent = 'Clear search';
		clear_button.classList = widgetParams.cssClasses.reset;
		clear_button.classList.add( 'hidden' );
		clear_button.setAttribute( 'type', 'button' );

		const submit_button = document.createElement( 'button' );
		submit_button.textContent = 'Search';
		submit_button.classList = widgetParams.cssClasses.submit;
		submit_button.setAttribute( 'type', 'submit' );

		clear_button.addEventListener( 'click', ( ) => {
			clear( );
			clear_button.classList.add( 'hidden' );
		} );

		widgetParams.container.appendChild( form );
		widgetParams.container.appendChild( loadingIndicator );
		form.appendChild( inputContainer );
		inputContainer.appendChild( label );
		inputContainer.appendChild( input );
		inputContainer.appendChild( clear_button );
		form.appendChild( submit_button );
	}

	widgetParams.container.querySelector( 'input' ).value = query;
	widgetParams.container.querySelector( 'span' ).hidden = !isSearchStalled;
};

const customRefinementList = connectRefinementList( ( renderOptions ) => {
	/* eslint-disable indent */
	const { items, canRefine, refine, widgetParams } = renderOptions;

	const container = widgetParams.container;
	if ( !container ) {
		return;
	}
	if ( !canRefine ) {
		container.innerHTML = '';
		return;
	}

	container.innerHTML = `
		<p class="header-search__filter-heading">Filter</p>
		<div class="form__group">
		${items
			.map( item => `
			<div class="form__checkbox-outer">
				<input id="${ item.value }"
					class="form__checkbox"
					value="${ item.value }"
					type="checkbox"
					${ item.isRefined ? ' checked' : '' }
				>
				<label class="form__label" for="${ item.value }">${ item.label }</label>
			</div>`
			)
			.join( '' )}
		</div>
	`;

	container.querySelectorAll( '.form__checkbox' ).forEach( input => {
		input.addEventListener( 'click', event => {
			refine( event.currentTarget.value );
		} );
	} );
} );

// create custom widgets
const customSearchBox = connectSearchBox(
	renderSearchBox
);
