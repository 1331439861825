export const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
    prevBtn.addEventListener('click', embla.scrollPrev, false);
    nextBtn.addEventListener('click', embla.scrollNext, false);
  };
  export const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
    return () => {
      if (embla.canScrollPrev()) {
        prevBtn.removeAttribute('disabled');
      }
      else {
        prevBtn.setAttribute('disabled', 'disabled');
      }
  
      if (embla.canScrollNext()) {
        nextBtn.removeAttribute('disabled');
      }
      else {
        nextBtn.setAttribute('disabled', 'disabled');
      }
    };
};

