
export function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}

export function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}

export function throttle( callback, limit ) {
	let tick = false;
	return function() {
		if ( !tick ) {
			callback.call();
			tick = true;
			setTimeout( () => {
				tick = false;
			}, limit );
		}
	};
}
