<template>
	<!-- Ticket Selector -->
	<div class="mb-64">
		<div
			v-for="remaining_ticket_type, attendee_type_id in remaining_ticket_types"
			:key="attendee_type_id"
			class="form__ticket-selector"
			:class="{ 'form__ticket-selector--sold-out': !tickets_remaining( attendee_type_id ) && !tickets_in_session( attendee_type_id ) }"
		>
			<label for="adult">{{ remaining_ticket_type.type }} Ticket - &pound;{{ remaining_ticket_type.cost }}</label>
			<div
				v-if="tickets_in_session( attendee_type_id ) || tickets_remaining( attendee_type_id )"
				v-show="!loading"
				class="form__ticket-selector__input-wrapper"
			>
				<button
					type="button"
					aria-label="Decrease ticket count"
					class="form__ticket-selector__button form__ticket-selector__button--minus"
					:disabled="!tickets_in_session( attendee_type_id )"
					@click="removeTicketBooking( attendee_type_id )"
				/>
				<!-- I've made this a span because the user isn't going to type in a number, I don't think -->
				<!-- So really it just displays the number -->
				<span>{{ current_session.tickets[attendee_type_id] ?? 0 }}</span>
				<!-- Input has min and max set - recommended -->
				<!-- <input
					id="{{ attendee_type_id }}"
					type="number"
					min="0"
					max="{{ remaining_ticket_type.remaining }}"
					value="0"
					name="{{ attendee_type_id }}-tickets"
					class="form__ticket-selector__input"
				> -->
				<button
					type="button"
					aria-label="Increase ticket count"
					class="form__ticket-selector__button form__ticket-selector__button--add"
					:disabled="!tickets_remaining( attendee_type_id )"
					@click="addTicketBooking( attendee_type_id )"
				/>
			</div>
			<div
				v-show="!loading"
				class="form__ticket-selector__tag-wrapper"
			>
				<div
					v-if="tickets_in_session( attendee_type_id ) || tickets_remaining( attendee_type_id )"
					class="tag"
				>
					{{ remaining_ticket_type.remaining }} tickets left
				</div>
				<div
					v-else
					class="tag"
				>
					Sold out
				</div>
			</div>
			<div
				v-show="loading"
			>
			</div>
			<div
				v-show="loading"
				class="form__ticket-selector__tag-wrapper"
			>
				<div class="tag">
					Loading...
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ApiClient from '../helpers/ApiClient';

const current_session = ref( [ ] );
const remaining_ticket_types = ref( [ ] );
const loading = ref( false );

function tickets_remaining( ticket_type ) { //eslint-disable-line
	const remaining_in_total = remaining_ticket_types.value[ticket_type].remaining;

	return remaining_in_total > 0;
}

function tickets_in_session( ticket_type ) {
	if ( null === current_session.value ) {
		return;
	}

	const in_session = current_session.value.tickets[ticket_type] ?? 0;

	return in_session > 0;
}

async function addTicketBooking( attendee_type_id ) {
	await ApiClient.addTicketBooking(
		{
			'attendee_type_id': attendee_type_id
		}
	)
		.then( response => {
			current_session.value = response;
			refreshRemainingTicketTypes();
		} )
		.catch( error => {
			console.log( error ); //eslint-disable-line
		} );
}

async function removeTicketBooking( attendee_type_id ) {
	await ApiClient.removeTicketBooking(
		{
			'attendee_type_id': attendee_type_id
		}
	)
		.then( response => {
			current_session.value = response;
			refreshRemainingTicketTypes();
		} )
		.catch( error => {
			console.log( error ); //eslint-disable-line
		} );
}

async function refreshRemainingTicketTypes() {
	loading.value = true;
	await ApiClient.getRemainingTicketTypes()
		.then( response => {
			remaining_ticket_types.value = response;
		} )
		.catch( error => {
			console.log( error ); //eslint-disable-line
		} );

	loading.value = false;
}

onMounted( async() => {
	await ApiClient.getSession()
		.then( ( response ) => {
			current_session.value = response;
		} )
		.catch( error => {
			console.log( error ); //eslint-disable-line
		} );

	await refreshRemainingTicketTypes();
} );

</script>
