import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mb-64" }
const _hoisted_2 = { for: "adult" }
const _hoisted_3 = {
  key: 0,
  class: "form__ticket-selector__input-wrapper"
}
const _hoisted_4 = ["disabled", "onClick"]
const _hoisted_5 = ["disabled", "onClick"]
const _hoisted_6 = { class: "form__ticket-selector__tag-wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "tag"
}
const _hoisted_8 = {
  key: 1,
  class: "tag"
}
const _hoisted_9 = { class: "form__ticket-selector__tag-wrapper" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Ticket Selector "),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.remaining_ticket_types, (remaining_ticket_type, attendee_type_id) => {
        return (_openBlock(), _createElementBlock("div", {
          key: attendee_type_id,
          class: _normalizeClass(["form__ticket-selector", { 'form__ticket-selector--sold-out': !$setup.tickets_remaining( attendee_type_id ) && !$setup.tickets_in_session( attendee_type_id ) }])
        }, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(remaining_ticket_type.type) + " Ticket - £" + _toDisplayString(remaining_ticket_type.cost), 1 /* TEXT */),
          ($setup.tickets_in_session( attendee_type_id ) || $setup.tickets_remaining( attendee_type_id ))
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("button", {
                  type: "button",
                  "aria-label": "Decrease ticket count",
                  class: "form__ticket-selector__button form__ticket-selector__button--minus",
                  disabled: !$setup.tickets_in_session( attendee_type_id ),
                  onClick: $event => ($setup.removeTicketBooking( attendee_type_id ))
                }, null, 8 /* PROPS */, _hoisted_4),
                _createCommentVNode(" I've made this a span because the user isn't going to type in a number, I don't think "),
                _createCommentVNode(" So really it just displays the number "),
                _createElementVNode("span", null, _toDisplayString($setup.current_session.tickets[attendee_type_id] ?? 0), 1 /* TEXT */),
                _createCommentVNode(" Input has min and max set - recommended "),
                _createCommentVNode(" <input\n\t\t\t\t\tid=\"{{ attendee_type_id }}\"\n\t\t\t\t\ttype=\"number\"\n\t\t\t\t\tmin=\"0\"\n\t\t\t\t\tmax=\"{{ remaining_ticket_type.remaining }}\"\n\t\t\t\t\tvalue=\"0\"\n\t\t\t\t\tname=\"{{ attendee_type_id }}-tickets\"\n\t\t\t\t\tclass=\"form__ticket-selector__input\"\n\t\t\t\t> "),
                _createElementVNode("button", {
                  type: "button",
                  "aria-label": "Increase ticket count",
                  class: "form__ticket-selector__button form__ticket-selector__button--add",
                  disabled: !$setup.tickets_remaining( attendee_type_id ),
                  onClick: $event => ($setup.addTicketBooking( attendee_type_id ))
                }, null, 8 /* PROPS */, _hoisted_5)
              ], 512 /* NEED_PATCH */)), [
                [_vShow, !$setup.loading]
              ])
            : _createCommentVNode("v-if", true),
          _withDirectives(_createElementVNode("div", _hoisted_6, [
            ($setup.tickets_in_session( attendee_type_id ) || $setup.tickets_remaining( attendee_type_id ))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(remaining_ticket_type.remaining) + " tickets left ", 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, " Sold out "))
          ], 512 /* NEED_PATCH */), [
            [_vShow, !$setup.loading]
          ]),
          _withDirectives(_createElementVNode("div", null, null, 512 /* NEED_PATCH */), [
            [_vShow, $setup.loading]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_9, _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "tag" }, " Loading... ", -1 /* HOISTED */)
          ]), 512 /* NEED_PATCH */), [
            [_vShow, $setup.loading]
          ])
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}